import { Link } from 'gatsby';
import React from 'react';
import '../styles/footer.scss';
import github from "../images/github.png";
import drupal from "../images/drupal.png";
import linkedin from "../images/linkedin.png";

export default function Footer() {
  return (
    <footer className='Footer'>
      <div className='Footer-Content'>
        <nav>
          <ul>
            <li>
              <Link to='/contact'>Contact Me</Link>
            </li>
            <li>
              <a href='https://github.com/bobby-saul' target="_blank" rel="noreferrer">
                <img src={github} alt="Bobby Saul's GitHub" />
              </a>
            </li>
            <li>
              <a href='https://www.drupal.org/u/bobbysaul' target="_blank" rel="noreferrer">
                <img src={drupal} alt="Bobby Saul's Drupal" />
              </a>
            </li>
            <li>
              <a href='https://www.linkedin.com/in/robert-saul-23983b132' target="_blank" rel="noreferrer">
                <img src={linkedin} alt="Bobby Saul's LinkedIn" />
              </a>
            </li>
          </ul>
        </nav>
        <div className='CopyRight'>
          <span>&copy; {(new Date()).getFullYear()}</span>
        </div>
      </div>
    </footer>
  )
}
