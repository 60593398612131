import React from "react";
import { Link } from "gatsby";
import '../styles/header.scss';
import logo from "../images/bobbysaul_logo.png";

export default function Header() {
  return (
    <header className="Header">
      <nav>
        <ul>
          <li>
            <Link to="/"><img src={logo} alt="Bobby Saul logo" /></Link>
          </li>
          <li>
            <Link to="/blog">Blog</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}
