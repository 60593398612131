import React from "react";
import Seo from "./SEO";
import '../styles/global.scss';
import Header from "./Header";
import Footer from "./Footer";

export default function Layout({ children, title, description, image }) {
  return (
    <>
      <Seo title={title} description={description} image={image} />
      {/* Header */}
      <Header />
      {/* Main */}
      <main>
        <div className="Main-Content">
          {children}
        </div>
      </main>
      {/* Footer */}
      <Footer />
    </>
  )
};
